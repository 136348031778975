import React from "react";
import { Container, Typography, Button, Grid } from "@mui/material";
import homeHero from "../../assets/images/home/home-hero.jpg";

const HeroSection = () => {
  return (
    <Container
      sx={{
        position: "relative",
        minHeight: "90vh",
        maxWidth: "100% !important",
        background: `url(${homeHero})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundPosition: "bottom",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-around",

        img: {
          position: "absolute",
          height: "100%",
          width: "100%",
        },
      }}
    >
      <Grid
        container
        sx={{
          position: "absolute",
          background: "#1934412e",
          width: "100%",
          height: "100%",
          zIndex: 1,
        }}
      ></Grid>
      <Grid
        item
        sx={{
          position: "absolute",
          padding: "20px",
          zIndex: 2,
        }}
      >
        <Typography
          variant="h2"
          component="div"
          sx={{ marginBottom: "20px", color: "#fff", span: { color: "#b4e0e5" } }}
        >
            <span>Explorez</span> le Monde
        </Typography>
        <Typography variant="h5" sx={{ color: "#fff", marginBottom: "40px" }}>
          Découvrez des destinations incroyables et planifiez votre prochaine
          aventure.
        </Typography>
        <Button
          variant="contained"
          sx={{
            background: "#193441",
            "&:hover": {
              background: "#193441ab",
            },
          }}
        >
          Voir les Destinations
        </Button>
      </Grid>
    </Container>
  );
};

export default HeroSection;
