import HeroSection from "../components/hero-section/heroSection";
import TopTravel from "../components/top-travel/top-travel";
import ImageMasonry from "../components/masonry/masonry";
import Separator from "../components/separator/separator";

const Homepage = () => {
  return (
    <>
      <HeroSection />
      <TopTravel />
      <Separator />
      <ImageMasonry />
      <Separator />
    </>
  );
};

export default Homepage;
