import React from "react";
import { AppBar, Toolbar, Typography } from "@mui/material";

const Footer = () => {
  return (
    <AppBar position="static" sx={{ background: '#193441' }}>
      <Toolbar>
        <Typography variant="body1" color="inherit">
          © {new Date().getFullYear()} Pulsing Travel. Tous droits réservés.
        </Typography>
      </Toolbar>
    </AppBar>
  );
};

export default Footer;
