import * as React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material";

export default function ImageMasonry() {
  const itemData = [
    {
      img: "https://images.unsplash.com/photo-1549388604-817d15aa0110",
      title: "Argentine",
    },
    {
      img: "https://images.unsplash.com/photo-1525097487452-6278ff080c31",
      title: "Finlande",
    },
    {
      img: "https://images.unsplash.com/photo-1523413651479-597eb2da0ad6",
      title: "USA",
    },
    {
      img: "https://images.unsplash.com/photo-1563298723-dcfebaa392e3",
      title: "Perou",
    },
    {
      img: "https://images.unsplash.com/photo-1588436706487-9d55d73a39e3",
      title: "Japon",
    },
    {
      img: "https://images.unsplash.com/photo-1574180045827-681f8a1a9622",
      title: "Liuanie",
    },
    {
      img: "https://images.unsplash.com/photo-1530731141654-5993c3016c77",
      title: "Chili",
    },
    {
      img: "https://images.unsplash.com/photo-1517487881594-2787fef5ebf7",
      title: "Norvege",
    },
    {
      img: "https://images.unsplash.com/photo-1516455207990-7a41ce80f7ee",
      title: "Estonie",
    },
  ];

  const ImageBackdrop = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    backgroundColor: theme.palette.common.black,
    opacity: 0.4,
    transition: theme.transitions.create("opacity"),
  }));

  const ImageMarked = styled("span")(({ theme }) => ({
    height: 3,
    width: 18,
    backgroundColor: theme.palette.common.white,
    position: "absolute",
    bottom: -2,
    left: "calc(50% - 9px)",
    transition: theme.transitions.create("opacity"),
  }));

  const Image = styled("span")(({ theme }) => ({
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: theme.palette.common.white,
  }));

  return (
    <>
      <Grid
        item
        md={12}
        sx={{
          position: "relative",
          maxHeight: "100vh",
          overflowY: "hidden",
          marginBottom: "4em",
        }}
      >
        <Typography
          variant="h4"
          noWrap
          sx={{
            mr: 2,
            display: { xs: "none", md: "flex" },
            fontFamily: '"Furore", sans-serif',
            fontWeight: 700,
            letterSpacing: ".3rem",
            color: "inherit",
            textDecoration: "none",
            justifyContent: "center",
          }}
        >
          Quelques cliches ...
        </Typography>
        <ImageList variant="masonry" cols={3} gap={8}>
          {itemData.map((item) => (
            <ImageListItem
              key={item.title}
              sx={{
                "&:hover": {
                  ".MuiImageBackdrop-root": {
                    opacity: 0,
                    cursor: "pointer",
                  },
                  ".MuiTypography-root": {
                    border: "4px solid #fff",
                  },
                  ".MuiImageMarked-root": {
                    opacity: 0,
                  },
                },
              }}
            >
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <img
                srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                src={`${item.img}?w=248&fit=crop&auto=format`}
                alt={item.title}
                loading="lazy"
              />
              <Image>
                <Link to={"/gallery"}>
                  <Typography
                    component="span"
                    variant={item.title}
                    sx={{
                      position: "relative",
                      color: '#fff !important',
                      p: 4,
                      pt: 2,
                      pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                    }}
                  >
                    {item.title}
                    <ImageMarked className="MuiImageMarked-root" />
                  </Typography>
                </Link>
              </Image>
            </ImageListItem>
          ))}
        </ImageList>
        <Box
          sx={{
            position: "absolute",
            bottom: 0,
            height: "55px",
            width: "100%",
            background: "linear-gradient(to top, #ffffff, transparent)",
          }}
        />
      </Grid>
      <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
        <Link to={"/gallery"}>
          <Button
            variant="contained"
            sx={{
              background: "#193441",
              "&:hover": {
                background: "#193441ab",
              },
            }}
          >
            Visualiser la galerie
          </Button>
        </Link>
      </Grid>
    </>
  );
}
