import {Divider} from "@mui/material";
import KeyboardDoubleArrowDownRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowDownRounded';

const Separator = () => {
    return <Divider sx={{
        margin: '4em',
        '&:before, &:after': {
            borderTop: 'thin solid #b4e0e5'
        }
    }}>
        <KeyboardDoubleArrowDownRoundedIcon sx={{ color: '#b4e0e5' }}/>
    </Divider>
}

export default Separator
