import { Grid } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/material/Container";
import "../../assets/fonts/fonts.scss";

const TopTravel = () => {
  return (
    <Container
      maxWidth="lg"
      sx={{
        position: "relative",
        minHeight: "350px",
        "@media (width <= 600px)": {
          padding: "25px 16px",
        },
      }}
    >
      <Grid
        sx={{
          display: "none",
          "@media (width <= 600px)": {
            display: "block",
          },
        }}
      >
        <Typography
          gutterBottom
          variant="h4"
          component="div"
          sx={{
            fontFamily: '"Furore", sans-serif',
            color: "#193441",
            span: { color: "#b4e0e5" },
          }}
        >
          Notre Top <span>3</span>
        </Typography>
      </Grid>
      <Grid
        container
        spacing={{ xs: 2, sm: 2, md: 3 }}
        columns={{ xs: 4, sm: 8, md: 12 }}
      >
        {Array.from(Array(3)).map((_, index) => (
          <Grid item xs={12} sm={4} md={4} key={index}>
            <Card
              sx={{
                marginTop: "-50px",
                background: "#fff",
                zIndex: 3,
                "@media (width <= 900px)": {
                  marginTop: index === 2 ? 0 : "-50px",
                },
                "@media (width <= 600px)": {
                  marginTop: 0,
                },
              }}
            >
              <CardMedia
                sx={{ height: 160 }}
                image="https://mui.com/static/images/cards/contemplative-reptile.jpg"
                title="green iguana"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" sx={{ color: '#b4e0e5' }}>
                  Lizard
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  Lizards are a widespread group of squamate reptiles, with over
                  6,000 species, ranging across all continents except Antarctica
                </Typography>
              </CardContent>
              <CardActions>
                <Button size="small">Decouvrir</Button>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
};

export default TopTravel;
