import { Grid } from "@mui/material";

const NotFound = () => {
  return (
    <Grid
      item
      md={12}
      sx={{
        minHeight: "calc(100vh - 64px)",
        textAlign: "center",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      This page has not been find.
    </Grid>
  );
};

export default NotFound;
